/* Assessment.css */

/* Container Styles */
.assessment-container {
  background-color: white;
  color: black;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem !important;
  margin-top: 2rem !important;
}

.assessment-heading {
  margin-bottom: 1rem !important;
  font-size: 2.1rem !important;
}

@media (min-width: 768px) {
  .assessment-heading {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 380px) {
  .assessment-heading {
    font-size: 1rem !important;
  }
}

/* Typography for description */
.assessment-text {
  margin-bottom: 1rem !important;
  font-size: 0.95rem;
}

@media (min-width: 768px) {
  .assessment-text {
    font-size: 1.1rem;
  }
}

@media (max-width: 380px) {
  .assessment-text {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 380px) {
  .assessment-score {
    font-size: 0.8rem !important;
  }
}


/* Stepper Navigation Buttons */
.assessment-nav-button {
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .assessment-nav-button {
    width: auto;
    margin-bottom: 0;
  }
}

.assessment-alert {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .assessment-alert {
    font-size: 1rem;
  }
}

@media (max-width: 320px) {
  .assessment-alert {
    font-size: 0.75rem;
  }
}

/* Form Input Fields */
.assessment-input {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 10px;
  font-size: 0.85rem;
}

@media (min-width: 768px) {
  .assessment-input {
    font-size: 1rem;
  }
}

@media (max-width: 320px) {
  .assessment-input {
    font-size: 0.75rem;
  }
}
