@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import "tokens.css";

body {
  background-color: var(--primary-color);
  color: var(--body-text-color);
  font-family: "Montserrat" !important;
  overflow-x: hidden;
}

.text {
  font-family: "Montserrat" !important;
  font-weight: 400;
  color: var(--body-text-color);
  text-transform: none;
}

.nav-text {
  font-family: "Montserrat" !important;
  font-weight: 550 !important;
  color: #d7d7d7 !important;
  text-transform: none;
}

.nav-text:hover {
  color: var(--body-text-color) !important;
}

.logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}

.title {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  color: var(--accent-color);
  text-transform: "none";
}

.pop {
  font-family: "Montserrat" !important;
  font-weight: 800 !important;
  color: var(--secondary-color);
  text-transform: "none";
}

.header {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  color: var(--body-text-color);
  text-transform: none;
}

.sub-text {
  font-family: "Montserrat" !important;
  font-weight: 400;
  font-size: 16px;
  color: var(--body-text-color);
}

.forum-text {
  font-family: "Montserrat" !important;
  font-weight: 400;
  color: var(--faded-body-text-color);
  text-transform: none;
}

.forum {
  background-color: #d9d9d9;
  border-radius: 10px;
  margin-bottom: 25px;
  height: 50px;
  margin-left: 3px;
}

.forum:hover {
  border: 3px solid #02518a;
  cursor: pointer;
}

.link:hover .text {
  color: var(--accent-color); /* Hover color */
}

.grey {
  font-family: "Montserrat" !important;
  color: var(--faded-body-text-color) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}